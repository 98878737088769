/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components), {SideBySide} = _components;
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Big_Innovation_2023.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com won two significant awards in the ", React.createElement(_components.strong, null, "2023 BIG Innovation Awards"), " presented by the Business Intelligence Group. Organizations from across the globe submitted their recent innovations for consideration in the BIG Innovation Awards. Nominations were then judged by a select group of business leaders and executives who volunteer their time and expertise to score submissions."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/news/press-releases/hear-com-wins-two-big-innovation-awards/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/press-releases-company-logo.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "UTRECHT, THE NETHERLANDS, May 13, 2021 – hear.com N.V., the largest online provider of expert, medical-grade hearing care globally, today announced that it has postponed plans for its initial public offering due to current challenging equity market conditions. The company will continue to monitor the market for more stable conditions."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/news/press-releases/hear-com-announces-postponement-of-its-initial-public-offering/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/press-releases-hearing-aids.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "UTRECHT, THE NETHERLANDS, April 19, 2021 – hear.com, the largest online provider of expert, medical-grade hearing care globally, today announced that it has filed a registration statement on Form F-1 with the U.S. Securities and Exchange Commission (the “SEC”) relating to the proposed initial public offering of the common shares of hear.com N.V. The offering is subject to market conditions, and there can be no assurance as to whether or when the offering may be completed, or as to the actual size or terms of the offering."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/news/press-releases/filing-of-registration-statement/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/press-releases-technology.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "After many years of research and input from millions of consultations, hear.com is excited to announce the launch of the Horizon hearing aid, the first hearing device designed exclusively for active, younger people. Founders Dr. Marco Vietor and Paul Crusius co-developed this unique, next-generation hearing device in partnership with German engineers from Signia, a top global hearing aid manufacturer."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/news/press-releases/hear-com-unveils-unprecedented-next-generation-hearing-aid-horizon-ix/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/press-releases-new-york.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "When Dr. Marco Vietor and Paul Crusius, alumni of WHU, set out to make a positive difference in the world of hearing, they were determined to expand their efforts across the Atlantic. But first, they decided to start in Berlin by creating a hearing aid company called audibene."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/news/press-releases/success-story/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/press-releases-app.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com – a global leader in the hearing aid industry – is happy to announce the launch of their official smartphone app for both iOS and Android devices. With this new innovation, hear.com aims to deliver online hearing care that is faster, easier, and more effective than ever before!"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/news/press-releases/hear-app/",
    className: "c-md-a"
  }, "Read more"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
